<template>
    <div class="DataPrivacyAndConsentView ViewWrapper">
        <clv-head-meta :title="$tc('entity Data Privacy and Consent', 1)"></clv-head-meta>
        <view-header>
            <h1>{{ $tc('entity Data Privacy and Consent', 1) }}</h1>
        </view-header>
        <view-container>
            <div class="row mt-4">
                <div class="col-12 col-md-8">
                    <div class="gk-card p-0">
                        <div class="border-bottom" v-if="i18nAddOnEnabled">
                            <select class="form-control border-0 rounded-0" v-model="currentLanguageKey">
                                <option :label="$t('EN')" :value="'EN'">{{ $t('EN') }}</option>
                                <option :label="$t('EL')" :value="'EL'">{{ $t('EL') }}</option>
                            </select>
                        </div>
                        <div class="py-3 px-4">

                            <!-- LOADER -->
                            <div class="ElementLoading-Placeholder" v-if="stateIsChanging">
                                <element-loading :active="true" background-color="transparent"></element-loading>
                            </div>

                            <!-- EMPTY STATE 2020-07-19 -->
                            <simple-state-not-found v-if="notFound">
                                <p class="mb-0 size-6" slot="title">{{ $t('common_state_not_found') }}</p>
                                <p class="mb-0 text-muted small" slot="description">{{ $t('Please contact support') }}.</p>
                            </simple-state-not-found>

                            <el-form :model="formFields"
                                     :rules="formFieldsRules"
                                     ref="newInstanceForm"
                                     :label-position="'top'"
                                     class="cltlr-form-label-style-1"
                                     @submit.native.prevent
                                     v-if="displayForm">

                                <el-form-item :label="$t('Legitimate Interest')" prop="legitimateInterest">
                                    <el-input type="textarea" autosize v-model="formFields.legitimateInterest"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Privacy Policy')" prop="privacyPolicy">
                                    <el-input type="textarea" autosize v-model="formFields.privacyPolicy"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Privacy Policy URL')" prop="privacyPolicyUrl">
                                    <el-input v-model="formFields.privacyPolicyUrl"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Consent to Communicate')" prop="consentToCommunicate">
                                    <el-input type="textarea" autosize v-model="formFields.consentToCommunicate"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Consent to Communicate (Accept)')" prop="consentToCommunicateAccept">
                                    <el-input type="textarea" autosize v-model="formFields.consentToCommunicateAccept"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Consent to Process Data - Explicit')" prop="consentToProcessDataExplicit">
                                    <el-input type="textarea" autosize v-model="formFields.consentToProcessDataExplicit"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Consent to Process Data - Explicit (Accept)')" prop="consentToProcessDataExplicitAccept">
                                    <el-input type="textarea" autosize v-model="formFields.consentToProcessDataExplicitAccept"></el-input>
                                </el-form-item>

                                <el-form-item :label="$t('Consent to Process Data - Implicit')" prop="consentToProcessDataImplicit">
                                    <el-input type="textarea" autosize v-model="formFields.consentToProcessDataImplicit"></el-input>
                                </el-form-item>

                                <el-form-item class="mt-2 mb-0">
                                    <button type="button" class="btn btn-primary" @click="submitForm">{{ $t('common_action_save') }}</button>
                                    <!--<button type="button" class="btn btn-secondary btn-sm" @click="resetForm">Reset</button>-->
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { DataPrivacyAndConsentService } from '@/common/services/api.service';
import ElValidationUtils from '@/common/utils/el-validation.utils';

/**
 * View to manage DataPrivacyAndConsent domain.
 * It i18n add-on is enabled it will display a select box with the supported languages.
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'DataPrivacyAndConsentView',
    data () {
        return {
            tenantAccountId: null,
            tenantAccountDefaultLanguageKey: 'EN',
            currentLanguageKey: 'EN',
            i18nAddOnEnabled: window.cs3w.get('i18nAddOnEnabled', false),

            // Persistence //////////
            fetching: false,
            saving: false,
            notFound: false,
            formFieldsDefault: {
                id: null,
                tenantAccountId: '',
                languageKey: 'EN',
                legitimateInterest: '',
                privacyPolicy: '',
                privacyPolicyUrl: '',
                consentToCommunicate: '',
                consentToCommunicateAccept: '',
                consentToProcessDataExplicit: '',
                consentToProcessDataExplicitAccept: '',
                consentToProcessDataImplicit: ''
            },
            formFields: {
                id: null,
                tenantAccountId: '',
                languageKey: 'EN',
                legitimateInterest: '',
                privacyPolicy: '',
                privacyPolicyUrl: '',
                consentToCommunicate: '',
                consentToCommunicateAccept: '',
                consentToProcessDataExplicit: '',
                consentToProcessDataExplicitAccept: '',
                consentToProcessDataImplicit: ''
            },
            formFieldsRules: {
                legitimateInterest: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ],
                privacyPolicy: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ],
                privacyPolicyUrl: [
                    ElValidationUtils.optionalValidator()
                ],
                consentToCommunicate: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ],
                consentToCommunicateAccept: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ],
                consentToProcessDataExplicit: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ],
                consentToProcessDataExplicitAccept: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ],
                consentToProcessDataImplicit: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(3, 1000)
                ]
            }
        };
    },
    computed: {
        stateIsChanging () {
            return this.fetching || this.saving;
        },
        displayForm () {
            return this.notFound === false && this.fetching === false && this.saving === false;
        }
    },
    watch: {
        'currentLanguageKey' (ignoredValue) {
            // Reset the form before fetch.
            this.resetForm();
            // Fetch the instance on each language change.
            this.fetchInstance();
        }
    },
    beforeMount () {
        // Get data from store.
        this.tenantAccountId = this.$store.getters['application/tenantAccountId'];
        this.tenantAccountDefaultLanguageKey = this.$store.getters['application/tenantAccountDefaultLanguageKey'];
        this.currentLanguageKey = this.tenantAccountDefaultLanguageKey;

        // Fetch instance for the first time.
        // We expect the instance with language key the default account's language key.
        this.fetchInstance();
    },
    methods: {
        fetchInstance () {
            // Reset notFound.
            this.notFound = false;

            // Start fetching.
            this.fetching = true;

            // Fetch instance.
            DataPrivacyAndConsentService.getDataPrivacyAndConsent(this.tenantAccountId, this.currentLanguageKey)
                .then(({ data }) => {
                    this.formFields = cloneDeep(data);
                    // @future : Make sure that no field is null.
                })
                .catch((ignoredError) => {
                    // Resource not found.
                    this.notFound = true;
                })
                .finally(() => {
                    // Stop fetching.
                    this.fetching = false;
                });
        },
        saveInstance () {
            // You should call 'submitForm' first. It validates the form.

            // Start loader.
            this.saving = true;

            // Perform the save request.
            DataPrivacyAndConsentService.saveDataPrivacyAndConsent(this.formFields)
                .then((ignoredData) => {
                    // use { data } if you need to access response data.
                    this.toastDefault('Settings updated');
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                })
                .finally(() => {
                    // Stop loader.
                    this.saving = false;
                });
        },

        // Form Controls //////////
        submitForm () {
            // Start loader.
            this.saving = true;

            // Validate form.
            this.$refs['newInstanceForm'].validate((valid) => {
                if (valid) {
                    this.saveInstance();
                } else {
                    this.saving = false;
                    return false;
                }
            });
        },
        resetForm () {
            // @help : try-catch is necessary because form sometimes is hidden (due to 'not-found'). Normal behavior.
            try {
                this.$refs['newInstanceForm'].resetFields();
            } catch (ignoredError) {}
        }
    }
};
</script>

<style lang="scss" scoped>
    .DataPrivacyAndConsentView {

        .el-textarea__inner {
            min-height: 120px !important;
        }

        .el-form-item {

            .el-textarea {

                textarea,
                .el-textarea__inner {
                    min-height: 120px !important;
                }
            }
        }
    }
</style>
